import * as React from 'react';
import { NavLink } from 'react-router-dom';

import Tag from 'antd/lib/tag';

import { Specialty } from '@commonTuna/react/objects/Specialty';

interface Props {
	specialty: Specialty;
	tagClassName?: string;
}

const SpecialtyTag: React.FC<Props> = (props) => {
	const specialty = props.specialty;

	return (<>
		{specialty.path
			? <NavLink to={`/specialties/${specialty.path}`}>
				<Tag color="blue" key={specialty.id} className={props.tagClassName}>
					{specialty.name}
				</Tag>
			</NavLink>
			: <Tag color="blue" key={specialty.id} className={props.tagClassName}>
				{specialty.name}
			</Tag>
		}
	</>);
};

export default SpecialtyTag;
