import React from 'react';

import * as Yup from 'yup';

import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';

import ServerPageProvider from '@common/react/components/Core/ServerPageProvider/ServerPageProvider';
import { ItemProvider } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { emailValidator, phoneRegexp } from '@common/react/utils/validationHelpers';
import { ItemEditor } from '@common/react/components/Core/ItemEditor/ItemEditor';
import FormikField from '@common/react/components/Forms/FormikField/FormikField';
import { FormikPhoneControl } from '@common/react/components/Forms/FormikPhoneControl/FormikPhoneControl';

import { Inquiry } from '@app/objects/Inquiry';

interface Props {
	inquiry?: Inquiry;
	withoutNewInquiryAfterSubmit?: boolean;
	withoutTitle?: boolean;
}

const { success, error } = Modal;

const phoneRequiredValidator = Yup.string().matches(phoneRegexp, 'Invalid phone number').required();

const validationSchema = Yup.object().shape({
	name: Yup.string().matches(/^[A-Za-z\s]+$/, 'Name should only contains latin letters')
		.max(50, 'Name must be no more than 50 characters').required(),
	company: Yup.string().matches(/^([0-9A-Za-z\s])+$/, 'Company should only contains digits and latin letters')
		.max(50, 'Company must be no more than 50 characters').required(),
	email: emailValidator,
	phone: phoneRequiredValidator,
});

interface InquiryInnerProps extends Omit<Props, 'withoutTitle'> {
	inquiry?: Inquiry;
	withoutNewInquiryAfterSubmit?: boolean;
	afterSubmit?: () => void;
	buttonTitle?: React.ReactNode;
}

export const InquiryInner: React.FC<InquiryInnerProps> = ({
	inquiry,
	withoutNewInquiryAfterSubmit,
	afterSubmit,
	buttonTitle = 'Save',
}) => {
	const onSave = React.useCallback(() => {
		success({
			title: 'Success',
			content: 'Your request has been sent successfully.',
		});
	}, []);

	const onRequestError = React.useCallback((err: string) => {
		error({
			title: 'Error',
			content: `Something went wrong. ${err}`,
		});
	}, []);

	return (
		<ItemProvider<Inquiry>
			type="inquiry"
			skipInitLoad
			id={inquiry?.id || -1}
			item={inquiry || { id: -1 } as Inquiry}
			transformAfterSave={(item) => ({ ...item, id: withoutNewInquiryAfterSubmit ? item?.id : -1 } as Inquiry)}
			validationSchema={validationSchema}
			loadRequest="getInquiry"
			saveRequest="inquiry"
			readonly={false}
			onSave={onSave}
			onRequestError={onRequestError}
		>
			<ItemEditor<Inquiry>
				afterSubmit={afterSubmit}
				getInitialValues={(item) => ({
					name: item?.name || '',
					company: item?.company || '',
					text: item?.text || '',
					phone: item?.phone || '',
					email: item?.email || '',
				})}
				showMessages={false}
				withButtons
				saveText={buttonTitle as any}
				edit={(formikBag, deleteItem, state) => (
					<div>
						<div className="row">
							<FormikField
								fieldName="name"
								title="Name*"
								inputProps={{
									maxLength: 50,
								}}
								containerClassName="form-group col-sm-6"
							/>
							<FormikField
								fieldName="company"
								title="Company*"
								inputProps={{
									maxLength: 50,
								}}
								containerClassName="form-group col-sm-6"
							/>
						</div>
						<div className="row">
							<FormikField
								fieldName="email"
								title="Email*"
								containerClassName="form-group col-sm-6"
							/>
							<FormikField
								containerClassName="form-group col-sm-6"
								fieldName="phone"
								title="Cell Phone*"
								render={(fieldProps) => <FormikPhoneControl
									fieldProps={fieldProps}
									placeholder="+1 (___) ___-____"
								/>
								}
							/>
						</div>
						<div className="row">
							<FormikField
								containerClassName="form-group col-sm-12"
								fieldName="text"
								title="Text"
								render={({ field }) =>
									<Input.TextArea
										maxLength={500}
										className="form-control input-textarea"
										style={{ resize: 'none' }}
										autoSize={{ minRows: 2, maxRows: 10 }}
										{...field}
									/>
								}
							/>
						</div>
					</div>
				)}
				view={() => <></>}
			/>
		</ItemProvider>
	);
};

const InquiryPage: React.FC<Props> = ({ inquiry, withoutNewInquiryAfterSubmit, withoutTitle }) => {
	return (
		<ServerPageProvider
			path="inquiry"
			customTitle="Inquiry"
			withoutHelmet={withoutTitle}
			inner={(page) => <div>
				<div className="container editor-page" style={{ background: '#fff', padding: 15 }}>
					{!withoutTitle && <>
						<div className="site-headline site-headline_with-button clearfix">
							<h1 className="pull-left">Inquiry</h1>
						</div>
					</>}
					<InquiryInner
						inquiry={inquiry}
						withoutNewInquiryAfterSubmit={withoutNewInquiryAfterSubmit}
					/>
				</div>
			</div>}
		/>
	);
};

export default InquiryPage;
