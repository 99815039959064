import React from 'react';
import { Link } from 'react-router-dom';

import Card from 'antd/lib/card';

import { Doctor } from '@app/objects/Doctor';
import DoctorAvatar from '@app/components/UI/DoctorCard/DoctorAvatar';
import Stars from '@app/components/UI/Stars/Stars';

interface Props {
	doctor: Doctor;
}

const DoctorCardHome: React.FC<Props> = ({ doctor }) => {
	const {
		nameEn,
		avatar,
		portalPathEn,
		averageReviewRate,
		portalShortText,
		totalReviewCount,
	} = doctor;

	const title = React.useMemo(() => <>
		<div className="row">
			<div className="col-sm-12">
				<div className="card-title">
					<h3 className="mt10 mb10">{portalPathEn ? <Link to={`/doctors/${portalPathEn}`}>{nameEn}</Link> : nameEn}</h3>
				</div>
				<div>
					<div className="doctor-rate mb10">
						<strong className="mr10">
							<Stars value={averageReviewRate || 0} />
						</strong>
						(
						{totalReviewCount || 0}
						)
					</div>
					{portalShortText
						? <div className="ant-card-meta-description mb10">{portalShortText}</div> : null
					}
				</div>
			</div>
		</div>
	</>, [nameEn, portalShortText, portalPathEn, averageReviewRate]);

	return (
		<div className="doctor-card">
			<Card style={{ width: '100%' }}>
				<Card.Meta
					avatar={<DoctorAvatar alt={nameEn} src={avatar} path={portalPathEn} hideWhenLoading />}
					title={title}
				/>
			</Card>
		</div>
	);
};

export default DoctorCardHome;
