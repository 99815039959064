import * as React from 'react';

import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import search from '@images/search.svg';
import calendar from '@images/calendar.svg';
import database from '@images/database.svg';

const cardsData = [
	{
		src: search,
		title: 'Find a Doctor',
		list: <>
			<li>in-demand specialties</li>
			<li>credentialed providers</li>
			<li>simple and efficient search</li>
			<li>see reviews and ratings from real patients</li>
		</>,
	},
	{
		src: calendar,
		title: 'Online Scheduling',
		list: <>
			<li>book an appointment in just three clicks</li>
			<li>add text or email appointment reminders</li>
			<li>save time by filling out necessary forms online</li>
			<li>sign consent forms and answer questionnaires</li>
		</>,
	},
	{
		src: database,
		title: 'After Your Appointment',
		list: <>
			<li>pay your bill online</li>
			<li>access visit summaries and lab results</li>
			<li>sign up for promotions and discounts</li>
			<li>manage your future appointments</li>
		</>,
	},
];

const Card: React.FC<{title, src, style?, list}> = (props) => {
	const {
		title,
		src,
		style,
		list,
	} = props;
	const [loaded, setLoaded] = React.useState(false);

	return <div className="home__card">
		<div className="home__card-icon__container icon-circle">
			<ImageLazy
				className="home__card__image"
				alt={title}
				src={src}
				style={style}
				onLoad={() => setLoaded(true)}
			/>
			{loaded ? null : <div className="point-loading" />}
			<div className="circle" />
		</div>
		<h3 className="home__card__text mt10">
			{title}
		</h3>
		<ul className="home__info-list">
			{list}
		</ul>
	</div>;
};

const ForPatient: React.FC = () => {
	return (
		<div className="cards-block__container home__container text-center patients-block">
			<div className="banner">
				<div className="gradient-header">
					<h2 className="cards-block__title">If you are a patient</h2>
				</div>
			</div>
			<div className="cards-block">
				{cardsData.map((card) => <Card
					key={card.title}
					{...card}
				/>)}
			</div>
		</div>
	);
};

export default ForPatient;
