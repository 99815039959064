import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { NavLink } from 'react-router-dom';

import ImageWithSkeleton from '@commonTuna/react/components/UI/ImageWithSkeleton/ImageWithSkeleton';

import dummyAvatar from '@images/no-image-available.jpg';

interface Props {
	src: string;
	alt: string;
	path?: string;
	withLink?: boolean;
	hideWhenLoading?: boolean;
}

const DoctorAvatar: React.FC<Props> = ({
	path, src, alt, withLink = true, hideWhenLoading = true,
}) => {
	const [loading, setLoading] = React.useState(true);
	const [hide, setHide] = React.useState(hideWhenLoading);

	React.useEffect(() => {
		if (hideWhenLoading) {
			setHide(false);
		}
	}, []);

	const inner = (<>
		<ImageWithSkeleton
			skeleton={dummyAvatar}
			src={hide && loading ? '' : src ? `/remote/${src}` : dummyAvatar}
			onLoad={() => setLoading(false)}
			onError={() => setLoading(false)}
			alt={alt}
		/>
		{loading ? <ContentLoader
			width="100%"
			height="100%"
			viewBox="0 0 265 265"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb"
			uniqueKey={path}
		>
			<rect x="0" y="0" rx="25" ry="25" width="100%" height="100%" />
		</ContentLoader> : null}
	</>);

	return withLink ? <NavLink to={`/doctors/${path}`} className="ant-avatar">
		{inner}
	</NavLink> : inner;
};

export default DoctorAvatar;
